import React from 'react';

import Routes from './routes/router';
import './assets/styles/global.css';

const App = () => {
  return <Routes />;
};

export default App;
